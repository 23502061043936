const buttonData = [
  {
    src: "/tops.svg",
    name: "Shirts",
    dropdownItems: ["T-Shirts", "Long-Sleeves", "Crop-Tops"],
    links: [ 'https://templot.b-cdn.net/tshirt_1.jpg', 'https://templot.b-cdn.net/tshirt_2.jpg', 'https://templot.b-cdn.net/tshirt_3.jpg' ]
  },
  {
    src: "/outerwear.svg",
    name: "Jackets",
    dropdownItems: ["Jackets", "Hoodies", "Sweaters"],
    links:[ 'https://templot.b-cdn.net/sweat_3.jpg', 'https://templot.b-cdn.net/sweat_1.jpg', 'https://templot.b-cdn.net/sweat_2.jpg' ]

  },
  {
    src: "/poster.svg",
    name: "Posters",
    dropdownItems: ["Jeans", "Sweatpants", "Shorts"],
    links:[ 'https://templot.b-cdn.net/poster-1.jpg', 'https://templot.b-cdn.net/poster-2.jpg', 'https://templot.b-cdn.net/poster-3.jpg' ]
  },
  {
    src: "/headwear.svg",
    name: "Hats",
    dropdownItems: ["Baseball Caps", "Beanies", "Bucket Hats"],
    links:[ 'https://templot.b-cdn.net/cap-1.jpg', 'https://templot.b-cdn.net/cap-2.jpg', 'https://templot.b-cdn.net/cap-3.jpg' ]
  },
  {
    src: "/mugs.svg",
    name: "Mugs",
    dropdownItems: ["Sneakers", "Socks", "Sandals"],
    links:[ 'https://templot.b-cdn.net/mug-1.jpg', 'https://templot.b-cdn.net/mug-2.jpg', 'https://templot.b-cdn.net/mug-3.jpg' ]
  },
  {
    src: "/accessories.svg",
    name: "Bags",
    dropdownItems: ["Bags", "Jewelry", "Sunglasses"],
    links:[]
  },
  {
    src: "/electronics.svg",
    name: "Cases",
    dropdownItems: ["Phone Cases", "Laptop Cases", "Headphones"],
    links: [ 'https://templot.b-cdn.net/case-1.jpg', 'https://templot.b-cdn.net/case-2.webp', 'https://templot.b-cdn.net/case-3.webp' ]

  },
  {
    src: "/others.svg",
    name: "Other",
    dropdownItems: ["Stickers", "Posters", "Mugs"],
    links:[]
  },
];

export default buttonData;
